<template>
  <div class="publishPage" ref="publishPage">
    <div class="mainDisplayArea">
      <EditorQuill
        :postInfo="postInfo"
        @monitorContentHeight="monitorContentHeight"
        @getPublishPageHeight="getPublishPageHeight"
        :uploadedAttachmentsStatus="uploadedAttachmentsStatus"
        :publishPageHeight="publishPageHeight"
        :labelAreaHeight="labelAreaHeight"
        :typeOfPublicationHeight="typeOfPublicationHeight"
        :bottomOperatingAreaHeight="bottomOperatingAreaHeight"
      ></EditorQuill>
      <div class="operatingArea">
        <div
          class="expandCollapse"
          @click="changeEditorExpandedState"
          v-if="contentHeightExceeded && uploadedAttachmentsStatus !== ''"
        >
          <span
            class="iconfont_Me icon-down iconStyle"
            v-if="!$store.state.publishArticle.editorExpandedState"
          ></span>
          <span class="iconfont_Me icon-up iconStyle" v-else></span>
        </div>
        <LabelArea :postInfo="postInfo" ref="labelArea"></LabelArea>
        <TypeOfPublication
          :postInfo="postInfo"
          ref="typeOfPublication"
        ></TypeOfPublication>
        <BottomOperatingArea
          :postInfo="postInfo"
          ref="bottomOperatingArea"
          @hasUploadedAttachments="hasUploadedAttachments"
        ></BottomOperatingArea>
      </div>
    </div>
  </div>
</template>
<script>
import EditorQuill from "./editorQuill/index";
import LabelArea from "./labelArea";
import TypeOfPublication from "./typeOfPublication";
import BottomOperatingArea from "./bottomOperatingArea";
import { getPostInformation } from "../../../api/newVersion/postContentDetails";
export default {
  data() {
    return {
      statusTimer: null,
      // 编辑器内容高度超出
      contentHeightExceeded: false,
      // 是否已上传过附件
      uploadedAttachmentsStatus: "",
      // 帖子详情数据
      postInfo: {},
      // 当前发布页的高度
      publishPageHeight: 0,
      // 标签区域的高度
      labelAreaHeight: 0,
      // 帖子类型区域的高度
      typeOfPublicationHeight: 0,
      // 底部区域的高度
      bottomOperatingAreaHeight: 0,
    };
  },
  components: {
    EditorQuill,
    LabelArea,
    TypeOfPublication,
    BottomOperatingArea,
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 用于监听进入发布页面
      if (to.name === "newPublish" && to.params.id && to.params.id !== "") {
        vm.gigId = vm.$Base64.decode(vm.$route.params.id.replace("pai_", ""));
        await vm.getPostInformation(vm.gigId);
      }
    });
  },
  beforeDestroy() {
    if (this.statusTimer) {
      clearTimeout(this.statusTimer);
    }
  },
  methods: {
    // 开始获取帖子的详细信息
    getPostInformation(gigId) {
      return new Promise((resolve, reject) => {
        this.postInfo = {};
        this.$nextTick(async () => {
          let result = await getPostInformation(gigId);
          if (result.code === 200) {
            this.postInfo = result.data.data;
            // 这一步是为了触发发布按钮可点击状态
            this.postInfo.attachments = this.postInfo.attachments.map(
              (item) => {
                item.progress = "100.00";
                return item;
              }
            );
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          this.$nextTick(() => {
            resolve("success");
          });
        });
      });
    },
    // 已上传了附件
    hasUploadedAttachments(status) {
      this.uploadedAttachmentsStatus = status;
    },
    // 富文本编辑器内容高度超出
    monitorContentHeight(contentHeightExceeded) {
      this.contentHeightExceeded = contentHeightExceeded;
    },
    // 获取当前发布页的高度
    getPublishPageHeight() {
      // 获取发布页的高度
      this.publishPageHeight = this.$refs.publishPage.offsetHeight - 16;
      // 获取标签区域的高度
      this.labelAreaHeight = this.$refs.labelArea.getLabelAreaHeight();
      // 获取帖子类型区域的高度
      this.typeOfPublicationHeight = this.$refs.typeOfPublication.getTypeOfPublicationHeight();
      // 获取底部区域的高度
      this.bottomOperatingAreaHeight = this.$refs.bottomOperatingArea.getBottomOperatingAreaHeight();
    },
    // 改变富文本编辑器的展开状态
    changeEditorExpandedState() {
      this.$store.commit(
        "setEditorExpandedState",
        !this.$store.state.publishArticle.editorExpandedState
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.publishPage
  width 100vw;
  height calc(100vh - 60px);
  box-sizing border-box;
  background #F7F7F7;
  overflow hidden;
  display flex;
  align-items flex-start;
  justify-content center;
  padding-top 16px;
  .mainDisplayArea
    width 900px;
    max-height 100%;
    padding 0 20px;
    box-sizing border-box;
    background #fff;
    overflow auto;
    .operatingArea
      width 100%;
      padding 0 15px;
      box-sizing border-box;
      .expandCollapse
        width 100%;
        height 20px;
        display flex;
        cursor pointer;
        align-items center;
        justify-content center;
        background transparent;
        .iconStyle
          color #8F8F8F;
          font-size 16px;
  .statusPopups
    top 50%;
    left 50%;
    transform translate3d(-50%,-50%,0);
    position fixed;
    z-index 999;
    width 9.375vw;
    height 9.375vw;
    border-radius 8px;
    background rgba(0,0,0,0.5);
    display flex;
    align-items center;
    justify-content center;
    .popupsInner
      width 3.65vw;
      height 3.65vw;
      border-radius 50%;
      overflow hidden;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 1.875vw;
        color #ffffff;
</style>
