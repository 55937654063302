import { Quill } from 'vue-quill-editor'
const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')
class Video extends BlockEmbed {
  static create (value) {
    const node = super.create(value)
    node.setAttribute('controls', 'controls');
    node.setAttribute('type', 'video/mp4');
    node.setAttribute('style', 'width:400px;height:200px;background:#000');
    node.setAttribute('webkit-playsinline', 'true') // 兼容ios 不全屏播放
    node.setAttribute('playsinline', 'true')
    node.setAttribute('x-webkit-airplay', 'allow') // 启用AirPlay支持
    node.setAttribute('x5-video-player-type', 'h5') // 启用H5播放器,是wechat安卓版特性
    node.setAttribute('x5-video-orientation', 'portraint') // 竖屏播放
    node.setAttribute('x5-playsinline', 'true') // 兼容安卓 不全屏播放
    node.setAttribute('x5-video-player-fullscreen', 'true')
    node.setAttribute('src', Link.sanitize(value));
    return node
  }
  static value(domNode) {
    // 输出到Delta内部内容
    return domNode.getAttribute('src');
  }
}
Video.blotName = 'video' // 需要调用的时候的标签
Video.tagName = 'video' //html标签

export default Video;