<template>
  <div class="pictureContent" ref="pictureContent">
    <div
      class="pictureItem"
      v-for="(item, index) in uploadedPicture"
      :key="item.path + index"
    >
      <div class="el-image-wrapper">
        <el-image
          class="imelink-image__icon"
          :src="
            formartImageUrl(item.path)
              ? formartImageUrl(item.path)
              : require('./grounGlass.png')
          "
          fit="cover"
          style="width: 100%; height: 100%"
        ></el-image>
        <el-progress
          v-if="item.progress < 100"
          type="circle"
          class="imelink-message-file__progress"
          :percentage="item.progress"
          color="#fff"
          :stroke-width="2"
          text-inside
          :width="40"
        />
      </div>
      <!-- 删除按钮 -->
      <div class="deleteThisPicture" @click="deleteThisPicture(index)">
        <span class="iconfont_Me icon-x deleteIconStyle"></span>
      </div>
      <!-- 取消上传按钮 -->
      <div
        class="deleteThisPicture cancleStyle"
        @click.stop="$emit('needCanclePictrue')"
        v-if="item.progress < 100"
      >
        <span class="iconfont_Me icon-x deleteIconStyle"></span>
      </div>
    </div>
    <div
      class="pictureItem seletPicture"
      style="cursor: pointer"
      @click="uploadNewPicture"
      v-if="uploadedPicture.length < 15"
    >
      <span class="iconfont_Me icon-plus addIconStyle"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    uploadedPicture: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    "uploadedPicture.length": {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.pictureContent.scrollLeft = 100000;
        });
      },
      deep: true,
    },
    // 监听上传进度
    uploadedPicture: {
      handler(newVal, oldval) {
        if (newVal) {
          newVal.map((item) => {
            item.progress = Math.round(Number(item.progress));
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    document.body.parentNode.style.overflow = "hidden";
    this.scrollInit();
  },
  destroyed() {
    try {
      document.body.parentNode.style.overflow = "visible";
      this.$refs.pictureContent.removeEventListener(
        "mousewheel",
        this.scrollHandler
      );
    } catch (error) {}
  },
  methods: {
    // 初始化与绑定监听事件方法
    scrollInit() {
      this.$refs.pictureContent.addEventListener(
        "mousewheel",
        this.scrollHandler,
        false
      );
    },
    scrollHandler(event) {
      const detail = event.wheelDelta || event.detail;
      const moveForwardStep = 1;
      const moveBackStep = -1;
      let step = 0;
      if (detail < 0) {
        step = moveForwardStep * 100;
      } else {
        step = moveBackStep * 100;
      }
      this.$refs.pictureContent.scrollLeft += step;
    },
    // 上传新图
    uploadNewPicture() {
      this.$emit("needUploadPictrue", 0);
    },
    // 删除上传的新图
    deleteThisPicture(index) {
      this.$emit("needDeletePictrue", index);
    },
  },
};
</script>
<style lang="stylus" scoped>

.el-image__placeholder{
  background:red!important;
}
.pictureContent {
  width: 100%;
  // height 104px;
  text-align: left;
  margin-bottom: 15px;
  // overflow-y: hidden;
  overflow-x: scroll;
  // display: flex
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
    background: rgba(204, 204, 204, 1);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 0;
    background: rgba(240, 240, 240, 0);
  }

  .pictureItem {
    width: 100px;
    // height 100px;
    // overflow hidden;
    // border-radius 8px;
    margin-right: 4px;
    position: relative;
    display: inline-block;

    .el-image-wrapper {
      border-radius: 8px;
      overflow: hidden;
      background: #F0F0F0;
      height: 100px !important;
      box-sizing: border-box;
      position: relative;
    }

    .imelink-message-file__progress {
      .el-progress-circle {
        background: transparent !important;
        position: relative;
      }

      .el-progress-circle::after {
        font-family: element-icons !important;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        font-size: 25px;
        transform: translate(-50%, -50%);
      }
    }



    .el-image {
      width: 100%;
      height: 100%;
    }

    .deleteThisPicture {
      top: 3px;
      right: 3px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .deleteIconStyle {
        color: #FFFFFF;
        font-size: 12px;
      }
    }

    .cancleStyle {
      background: red;
    }

    .addIconStyle {
      top: 50%;
      left: 50%;
      color: #8F8F8F;
      font-size: 30px;
      position: absolute;
      font-weight: bold;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .uploadStatus {
    font-size: 14px;
    color: #ff4d4d;
    text-align: center;
    padding-bottom: 10px;
  }

  .seletPicture {
    background: #f0f0f0;
    height: 100px !important;
    // margin-bottom: 14px;
    border-radius: 8px;
  }
}


  .el-image__placeholder{
      background: url('./grounGlass.png') no-repeat!important;
    }
</style>
