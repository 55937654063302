import { Quill } from 'vue-quill-editor'
const BlockEmbed = Quill.import('blots/block/embed')
class HyperlinkPreview extends BlockEmbed {
  static create(value){
    const node = super.create(value);
    node.setAttribute('contenteditable', false);
    node.setAttribute('style', 'width:100%;max-height:150px;background:transparent;margin:15px 0');
    node.dataset.pageCrawl = value;
    const pageCrawl = JSON.parse(value);
    let hyperlinkPreview =
    `<div style='width:70%;height:90px;border-radius:8px;overflow:hidden;position:relative;'>\
      <div style='position:absolute;top:8px;right:8px;z-index:2;width:18px;height:18px;cursor:pointer;display:flex;align-items:center;justify-content:center;border-radius:50%;background:rgba(0,0,0,0.2);' onclick='closeHyperlinkBlock(this)'>\
        <span class='iconfont_Me icon-x' style='font-size:12px;color:#fff'></span>\
      </div>\
      <a href=${pageCrawl.url} target='_blank' style='position:absolute;top:0px;left:0px;z-index:1;padding:0 24px 0 14px;text-decoration:none;width:100%;height:100%;background:#F7F7F7;display:flex;align-items:center;justify-content:flex-start;box-sizing:border-box'>\
        <div style='width:100%;height:70px;display:flex;'>\
          <div style='width:70px;height:70px;overflow:hidden;display:flex;align-items:center;justify-content:center'>\
            <img style='width:100%;height:100%;object-fit:cover' src=${pageCrawl.image&&pageCrawl.image!='' && pageCrawl.image.indexOf('http') !=-1 ?pageCrawl.image:'https://tfile.melinked.com/2021/04/0b5a6b1f-acd0-488d-9fdc-a0cdd83220d1.png'}>\
          </div>\
          <div style='display:flex;flex-flow:column;align-items:flex-start;justify-content:flex-start;height:70px;flex:1;width: 0;overflow:hidden;box-sizing:border-box;padding-left: 15px;'>\
            <div style='width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;line-height:20px;text-align:left;font-size:14px;font-weight:bold;color:#373737'>${pageCrawl.title}</div>\
            <div style='width:100%;text-align:left;font-size:12px;color:#373737;margin-top:9px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;'>${pageCrawl.content}</div>\
          </div>\
        </div>\
      </a>\
    </div>`
    node.innerHTML = hyperlinkPreview;
    return node;
  }
  static value(domNode) {
    // 输出到Delta内部的内容
    return domNode.dataset.pageCrawl;
  }
}
HyperlinkPreview.blotName = 'hyperlinkPreview' // 需要调用的时候的标签
HyperlinkPreview.className = 'ql-hyperlinkPreview'
HyperlinkPreview.tagName = 'div' //html标签

export default HyperlinkPreview;