var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "publishPage", staticClass: "publishPage" }, [
    _c(
      "div",
      { staticClass: "mainDisplayArea" },
      [
        _c("EditorQuill", {
          attrs: {
            postInfo: _vm.postInfo,
            uploadedAttachmentsStatus: _vm.uploadedAttachmentsStatus,
            publishPageHeight: _vm.publishPageHeight,
            labelAreaHeight: _vm.labelAreaHeight,
            typeOfPublicationHeight: _vm.typeOfPublicationHeight,
            bottomOperatingAreaHeight: _vm.bottomOperatingAreaHeight
          },
          on: {
            monitorContentHeight: _vm.monitorContentHeight,
            getPublishPageHeight: _vm.getPublishPageHeight
          }
        }),
        _c(
          "div",
          { staticClass: "operatingArea" },
          [
            _vm.contentHeightExceeded && _vm.uploadedAttachmentsStatus !== ""
              ? _c(
                  "div",
                  {
                    staticClass: "expandCollapse",
                    on: { click: _vm.changeEditorExpandedState }
                  },
                  [
                    !_vm.$store.state.publishArticle.editorExpandedState
                      ? _c("span", {
                          staticClass: "iconfont_Me icon-down iconStyle"
                        })
                      : _c("span", {
                          staticClass: "iconfont_Me icon-up iconStyle"
                        })
                  ]
                )
              : _vm._e(),
            _c("LabelArea", {
              ref: "labelArea",
              attrs: { postInfo: _vm.postInfo }
            }),
            _c("TypeOfPublication", {
              ref: "typeOfPublication",
              attrs: { postInfo: _vm.postInfo }
            }),
            _c("BottomOperatingArea", {
              ref: "bottomOperatingArea",
              attrs: { postInfo: _vm.postInfo },
              on: { hasUploadedAttachments: _vm.hasUploadedAttachments }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }