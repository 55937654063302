import http from "@/utils/http";
import { objectToUrlParams } from "@/utils/url";

// 获取网页内容API
export function getPageCrawl(params) {
  return http({
    baseURL: "/",
    method: "post",
    url: `rest/v5/pageCrawl/get`,
    data: params
  });
}
// 发布帖子
export function postAPost(params) {
  return http({
    baseURL: "/",
    method: "put",
    url: `rest/v5/helogig/`,
    data: params
  });
}