<template>
  <div class="bottomContent" ref="bottomContent">
    <div class="showUploadedArea" v-show="whetherToShow">
      <!-- 展示已上传的图片 -->
      <ShowPictureArea
        v-if="canUploadPicture && uploadedPicture.length != 0"
        :uploadedPicture="uploadedPicture"
        @needDeletePictrue="deleteThisPictrue"
        @needCanclePictrue="cancleThisPictrue"
        @needUploadPictrue="selectThisUploadButton"
      ></ShowPictureArea>
      <!-- 展示已上传的视频 -->
      <ShowVideoArea
        v-if="canUploadVideo && uploadedVideo.length != 0"
        :uploadedVideo="uploadedVideo[0]"
        @needDeleteVideo="deleteThisVideo"
        @needCancleVideo="cancleThisVideo"
        @getDuration="getDuration"
      >
      </ShowVideoArea>
      <!-- 展示已上传的文件 -->
      <ShowFileItem
        v-if="canUploadFile && uploadedFile.length != 0"
        :fileInfo="uploadedFile[0]"
        :needDelete="true"
        @deleteThisFile="deleteThisFile"
        @cancleThisFile="cancleThisFile"
      ></ShowFileItem>
    </div>
    <div class="buttonArea">
      <div class="uploadArea">
        <div
          class="uploadStyle"
          :key="item.iconClass"
          v-for="(item, index) in uploadButtons"
          @click="selectThisUploadButton(index)"
          :style="{
            background: item.isSelected ? '#F2FCF8' : '',
            cursor: attachmentUploaded(item.iconClass)
              ? 'not-allowed'
              : 'pointer',
          }"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.tooltipContent"
            placement="bottom"
          >
            <span
              class="iconfont_Me iconStyle"
              :class="[item.iconClass, item.isSelected ? 'iconSelected' : '']"
              :style="{
                color: attachmentUploaded(item.iconClass) ? '#8F8F8F' : '',
              }"
            ></span>
          </el-tooltip>
        </div>
      </div>
      <el-button
        class="submitArea commomButtonHover"
        @click="startSubmit"
        :disabled="disabledSubmit"
        :class="disabledSubmit ? 'disabledSubmit' : ''"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('post')"
          placement="bottom"
        >
          <span class="iconfont_Me icon-send iconStyle"></span>
        </el-tooltip>
      </el-button>
    </div>
    <div v-show="false">
      <el-upload
        v-show="false"
        ref="uploadPicture"
        action="/"
        accept="image/*"
        :multiple="true"
        :http-request="handlerUpload"
        :before-upload="beforePictrueUpload"
      >
      </el-upload>
      <el-upload
        v-show="false"
        ref="uploadVideo"
        action="/"
        accept="video/*"
        :multiple="false"
        :http-request="handlerUpload"
        :before-upload="beforeVideoUpload"
      >
      </el-upload>
      <el-upload
        v-show="false"
        ref="uploadFile"
        action="/"
        :accept="$store.state.publishArticle.attachmentType"
        :multiple="false"
        :http-request="handlerUpload"
        :before-upload="beforeFileUpload"
      >
      </el-upload>
    </div>
  </div>
</template>
<script>
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import ShowPictureArea from "./showUpload/showPicture";
import ShowVideoArea from "./showUpload/showVideo";
import ShowFileItem from "../../components/showFileItem";
import { postAPost } from "@/api/newVersion/publishArticle/index.js";
export default {
  components: {
    ShowPictureArea,
    ShowVideoArea,
    ShowFileItem,
  },
  data() {
    return {
      // 上传按钮集合
      uploadButtons: [
        {
          isSelected: false,
          iconClass: "icon-picture-fill1",
          tooltipContent: this.$t("attchPictrue"),
        },
        {
          isSelected: false,
          iconClass: "icon-video-fill",
          tooltipContent: this.$t("attchVideo"),
        },
        {
          isSelected: false,
          iconClass: "icon-paperclip",
          tooltipContent: this.$t("attchFile"),
        },
      ],
      // 上传文件的类型
      uploadType: "",
      uploadButtonIndex: 0,
      // 上传图片展示集合
      uploadedPicture: [],
      // 上传视频展示集合
      uploadedVideo: [],
      // 上传文件展示集合
      uploadedFile: [],
      // 帖子类型
      documentType: 0,
      // 上传的图片名排序
      sortName: [],
      sendLoading: false,
    };
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    "uploadedPicture.length": {
      handler(val) {
        if (!val) {
          this.uploadButtons[0].isSelected = false;
        }
      },
      deep: true,
    },
    "uploadedVideo.length": {
      handler(val) {
        if (!val) {
          this.uploadButtons[1].isSelected = false;
        }
      },
      deep: true,
    },
    "uploadedFile.length": {
      handler(val) {
        if (!val) {
          this.uploadButtons[2].isSelected = false;
        }
      },
      deep: true,
    },
    // 监听是否上传了附件，并通知父组件
    hasUploadedAttachments: {
      handler(val) {
        this.$emit("hasUploadedAttachments", val);
      },
      deep: true,
    },
    // 判断是否有帖子ID
    "postInfo.gigId": {
      handler(val, old) {
        if (val && val != old) {
          // documentType: 0 为普通帖子；1为视频帖子；3为图片帖子；4为文件帖子
          let imageAttachments = [];
          let videoAttachments = [];
          let fileAttachments = [];
          if (this.postInfo.attachments.length !== 0) {
            this.postInfo.attachments.forEach((e) => {
              if (this.matchFileSuffixType(e.path) === "image") {
                imageAttachments.push(e);
              } else if (this.matchFileSuffixType(e.path) === "video") {
                videoAttachments.push(e);
              } else {
                fileAttachments.push(e);
              }
            });
          }
          if (videoAttachments.length !== 0) {
            this.uploadedVideo = [videoAttachments[0]];
            this.$store.commit("setEditorExpandedState", false);
          } else if (imageAttachments.length !== 0) {
            this.uploadedPicture = imageAttachments;
            this.$store.commit("setEditorExpandedState", false);
          } else if (fileAttachments.length !== 0) {
            fileAttachments[0].progress = "100.00";
            this.uploadedFile = [fileAttachments[0]];
            this.$store.commit("setEditorExpandedState", false);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    // 是否可上传图片(图片只能上传十五张)
    canUploadPicture() {
      return this.uploadedVideo.length == 0 && this.uploadedFile.length == 0;
    },
    // 是否可上传视频(视频只能上传一个)
    canUploadVideo() {
      return this.uploadedPicture.length == 0 && this.uploadedFile.length == 0;
    },
    // 是否可上传文件(视频只能上传一个)
    canUploadFile() {
      return this.uploadedPicture.length == 0 && this.uploadedVideo.length == 0;
    },
    // 是否展示上传附件区域
    whetherToShow() {
      return !this.$store.state.publishArticle.editorExpandedState;
    },
    // 富文本内容是否为空
    contentIsNull() {
      let handler = this.$store.state.publishArticle.publishEditorContent.replace(
        /<[^>]+>/g,
        ""
      );
      handler = this.globalTrim(handler);
      return (
        handler == "" &&
        this.$store.state.publishArticle.publishEditorContent.indexOf(
          "<video"
        ) === -1 &&
        this.$store.state.publishArticle.publishEditorContent.indexOf(
          "<img"
        ) === -1
      );
    },
    // 是否可以开始进行提交操作
    disabledSubmit() {
      let uploadIsSuccess =
        (this.uploadedPicture.length > 0 &&
          this.uploadedPicture.every((item) => item.progress >= 100)) ||
        (this.uploadedVideo.length > 0 &&
          this.uploadedVideo.every((item) => item.progress === "100.00")) ||
        (this.uploadedFile.length > 0 &&
          this.uploadedFile.every((item) => item.progress === "100.00"));
      let xxSuccess =
        this.uploadedPicture.length > 0 ||
        this.uploadedVideo.length > 0 ||
        this.uploadedFile.length > 0;
      if (
        this.$store.state.publishArticle.publishTypes.length !== 0 &&
        uploadIsSuccess
      ) {
        return false;
      } else if (
        this.$store.state.publishArticle.publishTypes.length !== 0 &&
        !this.contentIsNull
      ) {
        if (xxSuccess) {
          return !uploadIsSuccess;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    // 已上传有附件
    hasUploadedAttachments() {
      let attachmentsType = "";
      if (this.uploadedVideo.length !== 0) {
        attachmentsType = "video";
      } else if (this.uploadedPicture.length !== 0) {
        attachmentsType = "picture";
      } else if (this.uploadedFile.length !== 0) {
        attachmentsType = "file";
      } else {
        attachmentsType = "";
      }
      return attachmentsType;
    },
  },
  methods: {
    // 获取发布页底部高度
    getBottomOperatingAreaHeight() {
      return this.$refs.bottomContent.offsetHeight;
    },
    // 获取视频的总时长
    getDuration(duration) {
      if (this.uploadedVideo.length != 0) {
        this.uploadedVideo[0].duration = parseInt(duration * 1000);
      }
    },
    // 判断是否已上传过文件，改变未上传文件的按钮样式
    attachmentUploaded(className) {
      return (
        (className == "icon-picture-fill1" && !this.canUploadPicture) ||
        (className == "icon-video-fill" && !this.canUploadVideo) ||
        (className == "icon-paperclip" && !this.canUploadFile)
      );
    },
    // 发布成功过后将这条发布成功后的帖子处理成首页数据，插入首页列表的第一条
    handleDataForHomePage(gigId, finallyData) {
      return new Promise((resolve, reject) => {
        let handleData = {
          attachments: [],
          commentCount: "0",
          country: 0,
          detail: finallyData.detail,
          documentType: finallyData.documentType,
          friendStatus: false,
          gigId: gigId,
          iCanDo: finallyData.icanDo || "",
          likeCount: "0",
          likeProviders: [],
          likeStatus: false,
          name: this.$store.getters.userInfo.name,
          pageViewCount: "0",
          profilePhoto: this.$store.getters.userInfo.avatar,
          providerICanDo: "",
          providerId: this.$store.getters.userInfo.id,
          publishTime: this.getNowFormatDate(),
          richDetail: finallyData.richDetail,
          tags: finallyData.tags,
          vipStatus: this.$store.getters.userInfo.vipStatus,
        };
        if (finallyData.attachments && finallyData.attachments.length !== 0) {
          handleData.attachments = finallyData.attachments.map((e) => {
            return {
              name: e.name,
              path: e.path,
              size: e.size,
              type: e.type,
              dataType: e.type,
              duration: e.duration ? e.duration : "",
              appearanceSize: e.appearanceSize ? e.appearanceSize : "",
            };
          });
        }
        this.$store.commit("setDataToBeDisplayedOnTheHomepage", handleData);
        resolve("success");
      });
    },
    // 获取当前的时间，用于拼凑首页帖子数据
    getNowFormatDate() {
      let date = new Date();
      let month = date.getMonth() + 1;
      let curDate = date.getDate();
      let curHours = date.getHours();
      let curMinutes = date.getMinutes();
      let curSeconds = date.getSeconds();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
      }
      if (curHours >= 0 && curHours <= 9) {
        curHours = "0" + curHours;
      }
      if (curMinutes >= 0 && curMinutes <= 9) {
        curMinutes = "0" + curMinutes;
      }
      if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
      }
      let currentdate = `${date.getFullYear()}-${month}-${curDate} ${curHours}:${curMinutes}:${curSeconds}`;
      return currentdate;
    },
    // 在上传图片以前执行检查
    beforePictrueUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "image" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      if (this.matchFileSuffixType(file.name) !== "image") {
        this.$message({
          type: "error",
          message: this.$t("uploadImageErrTips"),
        });
      } else {
        this.$message({
          type: "error",
          message: "<200M",
        });
      }
      return false;
    },
    // 在上传视频以前执行检查
    beforeVideoUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "video" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      if (this.matchFileSuffixType(file.name) !== "video") {
        this.$message({
          type: "error",
          message: this.$t("uploadVideoErrTips"),
        });
      } else {
        this.$message({
          type: "error",
          message: "<200M",
        });
      }
      return false;
    },
    // 在上传文件以前执行检查
    beforeFileUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) !== "image" &&
        this.matchFileSuffixType(file.name) !== "video" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      this.$message({
        type: "error",
        message: "<200M",
      });
      return false;
    },
    // 开始上传数据
    startSubmit() {
      if (this.sendLoading) {
        return;
      }
      this.sendLoading = true;
      // 获取富文本编辑器内容的Object对象
      let editorContent = this.$store.state.publishArticle.editorObject.getContents();
      // 处理数组结构
      this.handleContent(editorContent.ops).then((finallyData) => {
        //处理帖子标题的问题
        this.handlePostTitle(finallyData).then((handleData) => {
          postAPost(handleData)
            .then(async (result) => {
              this.sendLoading = false;
              if (result.code == 200) {
                this.$message({
                  type: "success",
                  message: result.message,
                });
                await this.handleDataForHomePage(result.data.data, handleData);
                // 重置发布页面在状态管理器中的数据,避免第二次进入有缓存
                this.$store.dispatch("resetPublishStore");
                // 跳转首页
                this.routeJump({ name: "home" });
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
            });
        });
      });
    },
    // 在提交前处理帖子的标题的问题(只取第一行的标题)
    handlePostTitle(finallyData) {
      return new Promise((resolve, reject) => {
        // 深拷贝一下
        let handleData = _.cloneDeep(finallyData);
        // 这里检测<h2不检测<h2>是因为有可能出现内联样式
        if (
          handleData.richDetail.indexOf("<h2") !== -1 &&
          handleData.richDetail.indexOf("<h2") === 0 &&
          handleData.richDetail.indexOf("</h2>") !== -1
        ) {
          // 抓取富文本的中的标题
          let str1 = handleData.richDetail.slice(
            handleData.richDetail.indexOf(">") + 1,
            handleData.richDetail.indexOf("</h2>")
          );
          // 过滤掉标题中的html标签
          handleData.icanDo = str1
            .replace(/<.*?>/g, "")
            .replace(/&lt;.*?&gt;/g, "");
          // 将标题从富文本数据中删除
          handleData.richDetail = handleData.richDetail.slice(
            handleData.richDetail.indexOf("</h2>") + 5
          );
          // 将标题从数组结构中删除掉
          let detail = JSON.parse(handleData.detail);
          // 如果第一个确定是标题的话，直接删除了
          if (detail.length !== 0 && detail[0].content === handleData.icanDo) {
            detail.shift();
          }
          handleData.detail = JSON.stringify(detail);
        }
        resolve(handleData);
      });
    },
    // 富文本数据结构处理
    handleContent(editorContent) {
      return new Promise((resolve, reject) => {
        let handleData = [];
        editorContent.forEach((element) => {
          let handleObj = {};
          // 如果是文本的话
          if (
            element.insert &&
            typeof element.insert == "string" &&
            element.insert != "\n"
          ) {
            handleObj.key = "text";
            handleObj.content = element.insert;
            // 如果是处理过的文本的话
          } else if (
            element.insert &&
            typeof element.insert.quillText == "string" &&
            element.insert.quillText != "\n"
          ) {
            handleObj.key = "text";
            handleObj.content = element.insert;
            // 如果是超链接文本的话
          } else if (element.insert && element.insert.hyperlinkText) {
            handleObj.key = "url";
            handleObj.content = element.insert.hyperlinkText;
            // 如果是超链接预览的话
          } else if (element.insert && element.insert.hyperlinkPreview) {
            handleObj.key = "web";
            handleObj.content = {
              title: JSON.parse(element.insert.hyperlinkPreview).title,
              image: JSON.parse(element.insert.hyperlinkPreview).image,
              content: JSON.parse(element.insert.hyperlinkPreview).content,
              url: JSON.parse(element.insert.hyperlinkPreview).url,
            };
            // 如果是图片的话
          } else if (element.insert && element.insert.image) {
            handleObj.key = "image";
            handleObj.content = element.insert.image;
            // 如果是视频的话
          } else if (element.insert && element.insert.video) {
            handleObj.key = "video";
            handleObj.content = element.insert.video;
          }
          if (handleObj.key) {
            // 这里需要判断一下文本数据，是否有回车，如果有的话，根据回车将数据拆分为多个文本数据
            if (
              handleObj.key === "text" &&
              handleObj.content.indexOf("\n") !== -1
            ) {
              let splitArr = handleObj.content.split("\n").filter((item) => {
                return item !== "";
              });
              splitArr.forEach((item) => {
                handleData.push({
                  key: "text",
                  content: item.trim().replace(/&nbsp;/gi, " "),
                });
              });
            } else {
              try {
                handleObj.content = handleObj.content.replace(/&nbsp;/gi, " ");
              } catch (error) {
                console.log(error);
              }
              handleData.push(handleObj);
            }
          }
        });
        this.handleSubmitData(handleData).then((finallyData) => {
          resolve(finallyData);
        });
      });
    },
    // 整理上传数据结构
    handleSubmitData(handleData) {
      return new Promise(async (resolve, reject) => {
        let attachments = await this.handleSubmitFile();
        let publishEditorContent = this.$store.state.publishArticle
          .publishEditorContent;
        try {
          publishEditorContent = publishEditorContent.replace(
            /background:rgba[(]0,0,0,0.2[)]/g,
            "display:none"
          );
        } catch (error) {
          this.sendLoading = false;
        }
        publishEditorContent = publishEditorContent.replace(/&nbsp;/gi, " ");
        try {
          let base64List = this.$store.state.publishArticle.base64List;
          if (base64List.length) {
            base64List.map((base64) => {
              if (publishEditorContent.indexOf(base64.base64) !== -1) {
                publishEditorContent = publishEditorContent.replaceAll(
                  base64.base64,
                  base64.path
                );
                try {
                  handleData = handleData.map((data) => {
                    if (
                      data.key == "image" &&
                      data.content.indexOf("data:image/") !== -1
                    ) {
                      data.content = data.content.replaceAll(
                        base64.base64,
                        base64.path
                      );
                    }
                    return data;
                  });
                } catch (error) {
                  this.sendLoading = false;
                }
              }
              return base64;
            });
          }
        } catch (error) {
          this.sendLoading = false;
        }
        let submitData = {
          // 首页展示富文本数组格式
          detail: JSON.stringify(handleData),
          // 详情展示富文本HTML字符串
          richDetail: publishEditorContent.replace(/&nbsp;/gi, " "),
          // 附件列表
          attachments: attachments,
          // 帖子类别
          categoryId: this.handleSubmitCategoryId(),
          // 帖子类型
          documentType: this.documentType,
          // 帖子标签
          tags: this.handleSubmitTags(),
        };
        resolve(submitData);
      });
    },
    // 处理标签数据结构
    handleSubmitTags() {
      let tags = "";
      if (this.$store.state.publishArticle.publishLabels.length != 0) {
        this.$store.state.publishArticle.publishLabels.forEach((element) => {
          tags += element + ",";
        });
        tags = tags.slice(0, tags.length - 1);
      }
      return tags;
    },
    // 处理类型数据结构
    handleSubmitCategoryId() {
      let categoryId = "";
      if (this.$store.state.publishArticle.publishTypes.length != 0) {
        this.$store.state.publishArticle.publishTypes.forEach((element) => {
          categoryId += element.id + ",";
        });
        categoryId = categoryId.slice(0, categoryId.length - 1);
      }
      return categoryId;
    },
    // 处理附件数据结构
    handleSubmitFile() {
      return new Promise(async (resolve, reject) => {
        let files = [],
          attachments = [];
        // 如果是上传的图片
        if (this.uploadedPicture.length != 0) {
          files = this.uploadedPicture;
          for (let i = 0; i < files.length; i++) {
            let imageItem = await this.getImgInfo(files[i].path);
            // 获取图片的规格大小（宽x高）
            files[i].appearanceSize = `${imageItem.width}x${imageItem.height}`;
          }
          this.documentType = 3;
          // 如果是上传的视频
        } else if (this.uploadedVideo.length != 0) {
          files = this.uploadedVideo;
          this.documentType = 1;
          // 如果是上传的文件
        } else if (this.uploadedFile.length != 0) {
          files = this.uploadedFile;
          this.documentType = 4;
        }
        if (files.length !== 0) {
          files.forEach((element) => {
            let fileItem = {
              name: element.name,
              path: element.path,
              size: element.size.toString(),
              type: this.documentType,
              appearanceSize: element.appearanceSize
                ? element.appearanceSize
                : "",
              duration: element.duration ? element.duration : "",
            };
            attachments.push(fileItem);
          });
        }
        resolve(attachments);
      });
    },
    // 删除这张图片
    deleteThisPictrue(index) {
      if (this.uploadedPicture.length != 0) {
        this.uploadedPicture.splice(index, 1);
      }
    },
    //取消上传图片
    cancleThisPictrue() {
      this.subscription_2.unsubscribe();
      this.deleteThisPictrue();
    },
    // 删除已上传的视频
    deleteThisVideo() {
      if (this.uploadedVideo.length != 0) {
        this.uploadedVideo.splice(0, 1);
      }
    },
    // 取消上传视频
    cancleThisVideo() {
      this.subscription_2.unsubscribe();
      this.deleteThisVideo();
    },
    // 删除已上传的文件
    deleteThisFile() {
      if (this.uploadedFile.length != 0) {
        this.uploadedFile.splice(0, 1);
      }
    },
    cancleThisFile() {
      this.subscription_2.unsubscribe();
      this.deleteThisFile();
    },
    // 点击这个上传按钮
    selectThisUploadButton(index) {
      switch (index) {
        case 0:
          if (this.canUploadPicture && this.uploadedPicture.length < 15) {
            this.uploadType = "image";
            this.uploadButtonIndex = index;
            this.$refs.uploadPicture.$children[0].$refs.input.click();
          }
          break;
        case 1:
          if (this.canUploadVideo && this.uploadedVideo.length < 1) {
            this.uploadType = "video";
            this.uploadButtonIndex = index;
            this.$refs.uploadVideo.$children[0].$refs.input.click();
          }
          break;
        case 2:
          if (this.canUploadFile && this.uploadedFile.length < 1) {
            this.uploadType = "file";
            this.uploadButtonIndex = index;
            this.$refs.uploadFile.$children[0].$refs.input.click();
          }
          break;
      }
    },
    // 开始调用隐藏的上传图片按钮
    handlerUpload(files) {
      this.renderingButtonStyle();
      handlerUploadFileToQiniu(files).then(async (item) => {
        // 收起富文本编辑器
        this.$store.commit("setEditorExpandedState", false);
        if (this.uploadType === "image" && this.uploadedPicture.length < 15) {
          this.uploadedPicture.unshift(item);
          this.$refs.uploadPicture.clearFiles();
        } else if (this.uploadType === "video") {
          this.uploadedVideo.unshift(item);
          this.$refs.uploadVideo.clearFiles();
        } else if (this.uploadType !== "image" && this.uploadType !== "video") {
          this.uploadedFile.unshift(item);
          this.$refs.uploadFile.clearFiles();
        }
      });
    },
    // 获取当前图片的属性
    getImgInfo(pictureSrc) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = this.formartImageUrl(pictureSrc);
        img.onload = () => {
          resolve(img);
        };
      });
    },
    // 渲染上传按钮
    renderingButtonStyle() {
      this.uploadButtons = this.uploadButtons.map((e) => {
        e.isSelected = false;
        return e;
      });
      this.$set(this.uploadButtons[this.uploadButtonIndex], "isSelected", true);
    },
  },
};
</script>
<style lang="stylus" scoped>
.bottomContent {
  width: 100%;
  min-height: 40px;
  padding-bottom: 20px;

  .showUploadedArea {
    width: 100%;
    min-height: 0;
  }

  .buttonArea {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .uploadArea {
      width: 156px;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .uploadStyle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #F7F7F7;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s;

        .iconStyle {
          font-size: 22px;
          color: #8F8F8F;
          transition: color 0.3s;
        }

        .iconSelected {
          color: #33CC66;
        }

        &:hover {
          background: #F2FCF8;

          .iconStyle {
            color: #33CC66;
          }
        }
      }
    }

    .disabledSubmit {
      background: #D9D9D9 !important;
    }

    .submitArea {
      width: 93px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconStyle {
        font-size: 28px;
        color: #FFFFFF;
      }
    }
  }
}
</style>
