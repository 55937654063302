import { Quill } from 'vue-quill-editor'
const BlockEmbed = Quill.import('blots/block/embed')

class QuillText extends BlockEmbed {
  static create(value){
    value = value==true?'':value;
    const node = super.create(value);
    node.setAttribute('contenteditable', true);
    node.dataset.text = value;
    node.innerHTML = value;
    return node;
  }
  static value(domNode) {
    // 输出到Delta内部的内容
    return domNode.dataset.text;
  }
}
QuillText.blotName = 'quillText' // 需要调用的时候的标签
QuillText.className = 'ql-quillText'
QuillText.tagName = 'span' //html标签
export default QuillText;