var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showVideoContent" },
    [
      _vm.videoProgress != 100
        ? _c(
            "div",
            { staticClass: "uploadStatus" },
            [
              _c("el-progress", {
                attrs: {
                  width: 46,
                  "stroke-width": 3,
                  color: "#ffffff",
                  type: "circle",
                  percentage: _vm.videoProgress
                }
              }),
              _c("div", { staticClass: "showOffStatus" }, [
                _vm._m(0),
                _c("div", { staticClass: "showOffFilesize" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.uploadedFilesize) +
                      "/" +
                      _vm._s(_vm.totalFilesize) +
                      "\n      "
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.videoProgress < 100
        ? _c(
            "div",
            {
              staticClass: "deleteThisVideo cancleStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("needCancleVideo")
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-x deleteStyle" })]
          )
        : _vm._e(),
      _vm.videoProgress == 100
        ? _c(
            "div",
            {
              staticClass: "deleteThisVideo",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("needDeleteVideo")
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-x deleteStyle" })]
          )
        : _vm._e(),
      _vm.videoProgress == 100
        ? _c("VideoPlayer", {
            attrs: { config: _vm.videoConfig },
            on: { getDuration: _vm.getDuration }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uploadIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-Turnup uploadIconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }