var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "quillEditor",
      class: _vm.$store.state.publishArticle.editorExpandedState
        ? "unfoldStyle"
        : _vm.uploadedAttachmentsStatus !== ""
        ? `${_vm.uploadedAttachmentsStatus}EditorStyle`
        : "unfoldStyle"
    },
    [
      _vm.progress < 100 && _vm.progress > 0 && _vm.isShowLoading
        ? _c(
            "div",
            { staticClass: "loading" },
            [
              _c("el-progress", {
                attrs: {
                  width: 46,
                  "stroke-width": 3,
                  color: "#ffffff",
                  type: "circle",
                  percentage: _vm.progress
                }
              }),
              _c(
                "div",
                {
                  staticClass: "deleteThisPicture",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.cancleUpload()
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-x deleteIconStyle"
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("quillEditor", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.editorLoading,
            expression: "editorLoading"
          }
        ],
        ref: "myTextEditor",
        staticClass: "ql-editorInner",
        attrs: { options: _vm.editorOption },
        on: {
          ready: function($event) {
            return _vm.onEditorReady($event)
          },
          change: function($event) {
            return _vm.onEditorChange($event)
          },
          blur: function($event) {
            return _vm.onEditorBlur($event)
          }
        },
        model: {
          value: _vm.editorModel,
          callback: function($$v) {
            _vm.editorModel = $$v
          },
          expression: "editorModel"
        }
      }),
      _c("el-upload", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "uploadPictureBox",
        attrs: {
          accept: "image/*",
          action: "/",
          multiple: false,
          "show-file-list": false,
          "http-request": _vm.handlerUpload,
          "before-upload": _vm.beforePictrueUpload
        }
      }),
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "uploadVideoBox",
          attrs: {
            action: "/",
            accept: "video/*",
            "show-file-list": false,
            "http-request": _vm.handlerUpload,
            "before-upload": _vm.beforeVideoUpload
          }
        },
        [_c("i", { staticClass: "el-icon-video-camera" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }