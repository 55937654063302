import { Quill } from 'vue-quill-editor'
const BlockEmbed = Quill.import('blots/block/embed')

class HyperlinkText extends BlockEmbed {
  static create(value){
    const node = super.create(value);
    node.setAttribute('contenteditable', false);
    node.setAttribute('style', 'width:100%;max-height:150px;background:transparent;margin-top:5px;');
    node.dataset.href = value;
    const pageCrawl = JSON.parse(value);
    let hyperlinkText =
    `<div style='display:flex;align-items:center;justify-content flex-start;'>\
      <img style='width:16px;height:16px;margin-right:5px;' src='https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png'/>
      <a href=${pageCrawl.jumpLink?pageCrawl.jumpLink:pageCrawl.showLink} target='_blank' style='word-break: break-all;width:90%;color:#226CDB;font-size:14px;margin-right:25px;text-decoration:none;'>${pageCrawl.showLink}</a>\
      <div style='width:18px;height:18px;cursor:pointer;display:flex;align-items:center;justify-content:center;border-radius:50%;background:rgba(0,0,0,0.2);' onclick='closeHyperlinkBlock(this)'>\
        <span class='iconfont_Me icon-x' style='font-size:12px;color:#fff'></span>\
      </div>\
    </div>`;
    node.innerHTML = hyperlinkText;
    return node;
  }
  static value(domNode) {
    // 输出到Delta内部的内容
    return domNode.dataset.href;
  }
}
HyperlinkText.blotName = 'hyperlinkText' // 需要调用的时候的标签
HyperlinkText.className = 'ql-hyperlinkText'
HyperlinkText.tagName = 'div' //html标签
export default HyperlinkText;