var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "labelArea", staticClass: "labelArea" }, [
    _c(
      "div",
      { ref: "scrollBody", staticClass: "scrollBody" },
      _vm._l(_vm.labels, function(item, index) {
        return _c(
          "el-tooltip",
          {
            key: index + item,
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: _vm.$t("addTag"),
              placement: "top"
            }
          },
          [
            _c("Label", {
              style: { minWidth: "80px" },
              attrs: {
                CanBeDeleted: item != "" ? true : false,
                CanBeAdded: item == "" ? true : false,
                labelContent: item,
                labelIndex: index
              },
              on: {
                addNewLabelTag: _vm.addNewLabelTag,
                deleteLabelTag: _vm.deleteLabelTag
              }
            })
          ],
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }