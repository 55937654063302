<template>
  <div class="typeOfPublication" ref="typeOfPublication">
    <div class="whenClosed" @click="openTypesdialog">
      <div class="typesBody">
        <div 
          class="typeItem" 
          :class="hasSelectedType.length!=0?'hasSelected':''" 
          v-for="(item,index) in needToShowWhenClose" 
          :key="item.id">
          {{item.name}}
          <span v-if="hasSelectedType.length !== 0" class="iconfont_Me icon-x iconStyle" @click.stop="deleteThisType(item)"></span>
        </div>
      </div>
      <div class="blockIcon">
        <span class="iconfont_Me icon-down iconStyle"></span>
      </div>
    </div>
    <el-dialog
      :visible.sync="typesdialog"
      width="600"
      :before-close="closeTypesdialog"
      :center="true"
      class="typesDialog"
      :append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <span slot="title" class="iconfont_Me icon-class" style="font-size:36px;color:#373737"></span>
      <div class="whenOpen">
        <div
          class="typeItem"
          :class="item.isSelected?'hasSelected':''"
          @click="selectThis(item,index)"
          v-for="(item,index) in needToShowWhenOpen" 
          :key="item.id">
          {{item.name}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="checkButton" type="primary" @click="confirm">
          <span class="iconfont_Me icon-check" style="font-size:22px;color:#ffffff"></span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCategories } from "@/api/newVersion/pageHeader";
export default {
  data() {
    return {
      // 已选中的类型
      hasSelectedType: [],
      // 已选中但是还没有确认的
      unconfirmedTypes: [],
      // 总共类型集合
      categorys: [],
      typesdialog: false,
    }
  },
  computed: {
    // 关闭状态的展示列表
    needToShowWhenClose() {
      let showData = this.hasSelectedType;
      // 如果还没有选中的类型，则默认展示7个类型
      if(showData.length == 0) {
        showData = this.categorys.slice(0,8)
      }
      return showData;
    },
    // 展开状态的展示列表
    needToShowWhenOpen() {
      let showData = this.categorys;
      // 如果已有被选中的类型，则在展开时默认选中
      if(this.hasSelectedType.length != 0) {
        showData = showData.map((e) => {
          e.isSelected = false;
          this.hasSelectedType.forEach((el) => {
            if(el.id == e.id) {
              e.isSelected = true;
            }
          })
          return e;
        })
      } else {
        showData = showData.map((e) => {
          e.isSelected = false;
          return e;
        })
      }
      return showData;
    },
  },
  async mounted() {
    // 获取本地菜单
    await this.handlingGetCategories();
    let strongNav = window.localStorage.getItem("catchNavs");
    this.categorys = JSON.parse(strongNav);
    this.categorys.shift()
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    hasSelectedType: {
      handler(newval,oldval) {
        this.$store.commit('setPublishTypes',newval)
      },
      deep: true
    },
    // 判断是否有帖子ID
    'postInfo.gigId': {
      handler(val , old) {
        if(val && val != old) {
          // 是否有默认类型
          if(this.postInfo.categoryId !== '') {
            let categoryIds = this.postInfo.categoryId.split(',');
            let categorys = this.categorys.filter( e => {
              return categoryIds.some(item => item === e.id);
            })
            this.hasSelectedType = categorys
          }
        }
      },
      deep:true
    }
  },
  methods: {
    // 从后台获取类型列表，这里不能从本地记录里面拿，因为很header组件里的异步请求有可能还没有请求到数据，会拿到已经储存在本地的类型数据
    handlingGetCategories() {
      return new Promise( async (resolve, reject) => {
        let result = await getCategories();
        if(result.code == 200 ) {
          let navs = result.data.data;
          navs.unshift({
            category_id: 0,
            category_name: this.$t("all")
          });
          let catchNavs = [];
          navs.map(element => {
            let item = {
              id: element.category_id,
              name: element.category_name
            };
            catchNavs.push(item);
          });
          window.localStorage.setItem("catchNavs", JSON.stringify(catchNavs));
        } else {
          this.$message({
            type: "error",
            message: result.message
          })
        }
        resolve('success')
      })
    },
    // 打开弹窗
    openTypesdialog() {
      this.unconfirmedTypes = [...this.hasSelectedType];
      // 如果没有已选中的类型，则将类型全部设置为未选中
      if(this.hasSelectedType.length == 0) {
        this.categorys = this.categorys.map((e)=>{
          e.isSelected = false;
          return e;
        })
      }
      this.typesdialog = true;
    },
    // 获取帖子类型区域的高度
    getTypeOfPublicationHeight() {
      return this.$refs.typeOfPublication.offsetHeight
    },
    // 关闭弹窗
    closeTypesdialog() {
      this.unconfirmedTypes = [];
      this.typesdialog = false;
    },
    // 确认选择
    confirm() {
      this.hasSelectedType = [...this.unconfirmedTypes];
      this.closeTypesdialog();
    },
    // 删除类型
    deleteThisType(item) {
      this.hasSelectedType = this.hasSelectedType.filter(el => el.id != item.id );
    },
    // 选择或取消当前这个类型
    selectThis(item,index) {
      // 超过三个类型则禁止再选择
      let selectCount = this.needToShowWhenOpen.reduce((cur,next) => {
        if(next.isSelected){cur++};
        return cur
      },0)
      let selectedDisable = selectCount >= 3 ? true : false;
      if(!item.isSelected && !selectedDisable) {
        this.$set(this.categorys[index],'isSelected',true);
        this.unconfirmedTypes.push(item);
      } else {
        this.$set(this.categorys[index],'isSelected',false);
        this.unconfirmedTypes = this.unconfirmedTypes.filter(el => el.id != item.id )
      }
      this.$forceUpdate();
    }
  }
}
</script>
<style lang="stylus" scoped>
  .typeOfPublication
    width 100%;
    min-height 30px;
    margin-bottom 20px;
    .hasSelected
      box-sizing border-box;
      border 1px solid #33CC66 !important;
      background #F2FCF8 !important;
      color #33CC66 !important;
    .whenClosed
      width 100%;
      height 30px;
      display flex;
      flex-flow row;
      cursor pointer;
      justify-content space-between;
      .typesBody
        height 30px;
        flex 1;
        overflow hidden;
        .typeItem
          height 30px;
          padding 0 10px;
          margin-right 8px;
          line-height 30px;
          display inline-block;
          border: 1px solid #8f8f8f;
          border-radius: 7px;
          font-size 14px;
          font-weight: 400;
          text-align: left;
          color: #8f8f8f;
          user-select none;
          .iconStyle
            font-size 12px;
            color #8F8F8F;
            cursor pointer;
      .blockIcon
        height 30px;
        line-height 30px;
        .iconStyle
          font-size 18px;
          color #8F8F8F;
  .typesDialog
    .whenOpen
      width 100%;
      overflow hidden;
      position relative;
      .typeItem
        height 30px;
        padding 0 10px;
        margin 0 8px 6px 0;
        display inline-flex;
        border: 1px solid #8f8f8f;
        border-radius: 7px;
        font-size 12px;
        font-weight: 400;
        align-items center;
        justify-content flex-start;
        color: #8f8f8f;
        cursor pointer;
        user-select: none;
        transition color .3s, background .3s, border .3s;
        &:hover
          border: 1px solid #33CC66;
          color #33CC66
    .checkButton
      width 150px;
      height 40px;
      display inline-flex;
      align-items center;
      justify-content center;

</style>
<style lang="stylus">
  .typesDialog
    .el-dialog
      margin-top 0 !important;

</style>