<template>
  <div class="labelArea" ref="labelArea">
    <div class="scrollBody" ref="scrollBody">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('addTag')"
        placement="top"
        v-for="(item, index) in labels"
        :key="index + item"
      >
        <Label
          :style="{ minWidth: '80px' }"
          @addNewLabelTag="addNewLabelTag"
          @deleteLabelTag="deleteLabelTag"
          :CanBeDeleted="item != '' ? true : false"
          :CanBeAdded="item == '' ? true : false"
          :labelContent="item"
          :labelIndex="index"
        ></Label>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import Label from "../../components/label";
export default {
  components: {
    Label,
  },
  data() {
    return {
      labels: [""],
    };
  },
  watch: {
    labels: {
      handler(val) {
        this.$store.commit("setPublishLabels", val.slice(0, val.length - 1));
        this.$nextTick(() => {
          this.$refs.scrollBody.scrollLeft = 100000;
        });
      },
      deep: true,
    },
    // 判断是否有帖子ID
    "postInfo.gigId": {
      handler(val, old) {
        if (val && val != old) {
          if (this.postInfo.tags !== "") {
            this.labels = this.postInfo.tags.split(",");
            this.labels.push("");
          }
        }
      },
      deep: true,
    },
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    document.body.parentNode.style.overflow = "hidden";
    this.scrollInit();
  },
  destroyed() {
    try {
      document.body.parentNode.style.overflow = "visible";
      this.$refs.scrollBody.removeEventListener(
        "mousewheel",
        this.scrollHandler
      );
    } catch (error) {}
  },
  methods: {
    // 获取标签区域的高度
    getLabelAreaHeight() {
      return this.$refs.labelArea.offsetHeight;
    },
    // 初始化与绑定监听事件方法
    scrollInit() {
      this.$refs.scrollBody.addEventListener(
        "mousewheel",
        this.scrollHandler,
        false
      );
    },
    scrollHandler(event) {
      const detail = event.wheelDelta || event.detail;
      const moveForwardStep = 1;
      const moveBackStep = -1;
      let step = 0;
      if (detail < 0) {
        step = moveForwardStep * 100;
      } else {
        step = moveBackStep * 100;
      }
      this.$refs.scrollBody.scrollLeft += step;
    },
    // 添加新的标签
    addNewLabelTag(text, index) {
      if (this.labels[index] == "") {
        this.labels[index] = text;
        this.labels.push("");
      }
    },
    // 删除标签
    deleteLabelTag(index) {
      this.labels.splice(index, 1);
    },
  },
};
</script>
<style lang="stylus" scoped>
.labelArea
  width 100%;
  height 61px;
  padding 15px 0;
  box-sizing border-box;
  .scrollBody
    width 100%;
    height 40px;
    overflow-y: hidden;
    overflow-x scroll;
    white-space:nowrap;
    scrollbar-width: none;
    &::-webkit-scrollbar
      height 0px;
    &::-webkit-scrollbar-thumb
      border-radius: 2px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
      background: rgba(204,204,204,1);
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0,0,0,0);
      border-radius: 0;
      background: rgba(240,240,240,0);
</style>
